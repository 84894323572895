import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from '../model/usuario';
import { ConfiguracaoSistemaServico } from '../servicos/configuracaoSistema/configuracaoSistema.servico';
import { UsuarioServico } from '../servicos/usuario/usuario.servico';

//variavel para utilizar jquery
declare var $: any;

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isExpanded = false;
  public titulo = "SGP";
  public tempoEsperaBalcao: number = 0;
  public tempoEsperaEntrega: number = 0;
  public permiteTempoEstimado: boolean = false;
  public permiteAtendenteFecharComanda: boolean = false;
  public imprimirIfood: boolean = false;
  public imprimirAnotaAi: boolean = false;

  constructor(private router: Router, private usuarioServico: UsuarioServico, private configuracaoSistemaServico: ConfiguracaoSistemaServico) {

  }

  ngOnInit(): void {
    this.configuracaoSistemaServico.obterTodos()
      .subscribe(
        data => {
          if (data != null && data.length > 0) {
            data.forEach(config => {
              if (config.descricao == 'Titulo_Abertura') {
                this.titulo = config.valor;
              }
              else if (config.descricao == 'TempoEsperaBalcao') {
                this.tempoEsperaBalcao = parseInt(config.valor);
              }
              else if (config.descricao == 'TempoEsperaEntrega') {
                this.tempoEsperaEntrega = parseInt(config.valor);
              }
              else if (config.descricao == 'PermiteTempoEstimado' && config.valor == 'true') {
                this.permiteTempoEstimado = true;
                this.iniciarVerificacaoTempoEstimado();
              }
              else if (config.descricao == 'PermiteAtendenteFecharComanda' && config.valor == 'true') {
                this.permiteAtendenteFecharComanda = true;
              }
            });
          }
        },
        err => {
          console.log(err.error);
        }
    );

    localStorage.setItem("imprimirIfoodAutomatico", this.imprimirIfood.toString());
    localStorage.setItem("imprimirAnotaAiAutomatico", this.imprimirAnotaAi.toString());
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  public usuarioLogado(): boolean {
    return this.usuarioServico.usuario_autenticado();
  }

  sair() {
    this.usuarioServico.limpar_sessao();
    this.router.navigate(['/login']);
  }

  get usuario() {
    return this.usuarioServico.usuario;
  }

  public usuarioAutenticado(): Usuario {
    return this.usuarioServico.usuario;
  }

  public alterarTempoEstimado() {
    this.configuracaoSistemaServico.salvarTempoEstimado(this.tempoEsperaEntrega, this.tempoEsperaBalcao)
      .subscribe(
        data => {
          alert('Tempo estimado atualizado com sucesso')
        },
        err => {
          console.log(err.error);
        }
      );
  }

  abrirModalTempoEstimado() {
    var self = this;

    $(() => {
      $('#modalAlterarTempoEstimado').modal('show');
      $('#modalAlterarTempoEstimado')
        .off('shown.bs.modal')
        .on('shown.bs.modal', function () {

        });

      $('#modalAlterarTempoEstimado')
        .off('hidden.bs.modal')
        .on('hidden.bs.modal', function () {
          //self.pedidoSelecionado = new Pedido();
        });
    });
  }

  async iniciarVerificacaoTempoEstimado() {
    let timerId = setInterval(() => {
      this.verificarTempoEstimado()
    }, 60000);
  }

  verificarTempoEstimado() {
    this.configuracaoSistemaServico.obterTodos()
      .subscribe(
        data => {
          if (data != null && data.length > 0) {
            data.forEach(config => {
              if (config.descricao == 'TempoEsperaBalcao') {
                this.tempoEsperaBalcao = parseInt(config.valor);
              }
              else if (config.descricao == 'TempoEsperaEntrega') {
                this.tempoEsperaEntrega = parseInt(config.valor);
              }
            });
          }
        },
        err => {
          console.log(err.error);
        }
      );
  }

  ligaDesligaImpressaoIfood() {
    localStorage.setItem("imprimirIfoodAutomatico", this.imprimirIfood.toString());
  }

  ligaDesligaImpressaoAnotaAi() {
    localStorage.setItem("imprimirAnotaAiAutomatico", this.imprimirAnotaAi.toString());
  }
}
